export function getOptionsFromResponse(
  response,
  mapping = {
    label: "Text",
    value: "Value",
  }
) {
  return response.data.map((option) => {
    return {
      label: option[mapping.label],
      value: option[mapping.value],
      disabled: option.Disabled,
      selected: option.Selected,
    };
  });
}

export function TreeRegion(
  curentId,
  region,
  level,
  regionList,
  options = { keyName: "regionId" }
) {
  debugger;
  var title = "";
  for (var i = 1; i < level; i++) {
    title = title + "\\u00A0\\u00A0\\u00A0\\u00A0\\u00A0";
  }
  title = title + region.Title;
  const obj = {};
  obj.text = title;
  obj[options.keyName] = region.Id;

  regionList.push(obj);

  for (var i = 0; i < region.Childs.length; i++) {
    TreeRegion(curentId, region.Childs[i], level + 1, regionList, options);
  }
}

export function TreeUnitRequest(
  curentId,
  unit,
  level,
  unitList,
  options = { keyName: "Value" }
) {
  var title = "";
  for (var j = 1; j < level; j++) {
    title = title + "\\u00A0\\u00A0\\u00A0\\u00A0\\u00A0";
  }

  debugger;
  title = title + unit.Title;
  const objU = {};
  objU.Text = title;
  objU[options.keyName] = unit.Id;
  if (!unit.isRequestO) {
    objU.Disabled = true;
  }

  unitList.push(objU);

  for (var i = 0; i < unit.Childs.length; i++) {
    TreeUnit(curentId, unit.Childs[i], level + 1, unitList, options);
  }
}

export function getOptionsUnitRequest(
  response,
  mapping = {
    label: "Text",
    value: "Value",
  }
) {
  return response.map((option) => {
    return {
      label: option[mapping.label],
      value: String(option[mapping.value]),
      disabled: option.Disabled,
      selected: false,
    };
  });
}

export function TreeUnit(
  curentId,
  unit,
  level,
  unitList,
  options = { keyName: "Value" }
) {
  var title = "";
  for (var j = 1; j < level; j++) {
    title = title + "\\u00A0\\u00A0\\u00A0\\u00A0\\u00A0";
  }

  title = title + unit.Title;
  const objU = {};
  objU.Text = title;
  objU[options.keyName] = unit.Id;

  unitList.push(objU);

  for (var i = 0; i < unit.Childs.length; i++) {
    TreeUnit(curentId, unit.Childs[i], level + 1, unitList, options);
  }
}

export function getOptionsUnit(
  response,
  mapping = {
    label: "Text",
    value: "Value",
  }
) {
  return response.map((option) => {
    return {
      label: option[mapping.label],
      value: String(option[mapping.value]),
      disabled: false,
      selected: false,
    };
  });
}

export function showLocalSpinner(parent) {
  if (parent) {
    const spinnerBackdrop = document.createElement("div");
    const spinner = document.createElement("div");
    spinner.classList.add("loader");
    spinnerBackdrop.classList.add("spinner-backdrop-loc");

    spinnerBackdrop.appendChild(spinner);
    parent.appendChild(spinnerBackdrop);
  }
}

export function hideLocalSpinner(parent) {
  if (parent) {
    parent.querySelector(".spinner-backdrop-loc")?.remove();
  }
}

export function showSpinner() {
  hideSpinner();
  const body = document.body;
  const spinnerBackdrop = document.createElement("div");
  const spinner = document.createElement("div");
  spinner.classList.add("loader");
  spinnerBackdrop.classList.add("spinner-backdrop");

  spinnerBackdrop.appendChild(spinner);
  body.appendChild(spinnerBackdrop);
}

export function hideSpinner() {
  if (document.querySelectorAll(".spinner-backdrop").length > 0) {
    document.querySelectorAll(".spinner-backdrop").forEach((el) => {
      el.classList.add("spinner-fadeout");
      setTimeout(() => {
        if (el.parentNode != null) el.parentNode.removeChild(el);
      }, 200);
    });
    return;
  }
}

export function initTwoLengthValidatorAndMask(
  form,
  pristine,
  Inputmask,
  attr = "data-two-length-validator-and-mask"
) {
  form.querySelectorAll("[" + attr + "]").forEach((f) => {
    const lengths = f.getAttribute(attr).split("|");
    const firstLengthMask = "9".repeat(parseInt(lengths[0]));
    const secondLengthMask = "9".repeat(parseInt(lengths[1]));

    f._imask_object = Inputmask({
      mask: [firstLengthMask, secondLengthMask],
    }).mask(f);

    pristine.addValidator(
      f,
      (value) => {
        if (f.inputmask) {
          value = f.inputmask.unmaskedvalue();
        }
        if (
          value.length !== undefined &&
          (value.length === 0 ||
            value.length === parseInt(lengths[0]) ||
            value.length === parseInt(lengths[1]))
        ) {
          return true;
        }
        return false;
      },
      f.getAttribute(attr + "-message")
    );
  });
}
