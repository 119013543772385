import Swal from 'sweetalert2/src/sweetalert2.js';

export function modal(
    html,
    options
) {
    if (html != null && typeof html == 'string' && html.includes('<!DOCTYPE html>'))
        throw new Error('doc in modal');

    if (!options) {
        options = {};
    }

    const _options = {
        didOpenCallback: options.didOpenCallback || function () { },
        preConfirmCallback: options.preConfirmCallback || function () { },
        thenCallback: options.thenCallback || function () { },
        title: options.title || '',
        confirmButtonText: options.confirmButtonText,
        cancelButtonText: options.cancelButtonText,
        showCancelButton: options.showCancelButton 
    };

    let showConfirmButton = true;

    if (options.showConfirmButton !== undefined) {
        showConfirmButton = options.showConfirmButton;
    }

    Swal.fire({
        showConfirmButton: showConfirmButton,
        title: _options.title || null,
        width: options.width || 600,
        heightAuto: true,
        padding: 30,
        html: html || undefined,
        confirmButtonText: _options.confirmButtonText || 'Сохранить',
        cancelButtonText: _options.cancelButtonText || 'Отменить',
        showCancelButton: _options.showCancelButton,
        showDenyButton: options.showDenyButton,
        denyButtonText: options.denyButtonText,
        buttonsStyling: false,
        allowOutsideClick: false,
        customClass: {
            confirmButton: 'btn btn-danger btn-type-2',
            denyButton: 'btn btn-danger btn-type-2',
            title: 'modal-title',
            cancelButton: 'btn btn-default btn-type-2',
            container: 'modal-content',
        },
        didOpen: () => {
            const swalRef = Swal;
            _options.didOpenCallback(swalRef);
        },
        preConfirm: () => {
            const swalRef = Swal;
            return _options.preConfirmCallback(swalRef);
        },
        preDeny: function () {
            const swalRef = Swal;
            if (options.preDeny) {
                return options.preDeny(swalRef);
            }
        }
    }).then(function (result) {
        console.log(result);
        _options.thenCallback(result);
        
    });

}

export function errorModal(
    errorText,
    options
) {
    if (!options) {
        options = {};
    }

    const _options = {
        title: options.title || 'Ошибка',
        didOpenCallback: options.didOpenCallback || function () { },
        preConfirmCallback: options.preConfirmCallback || function () { },
        thenCallback: options.thenCallback || function () { },
    };

    let showConfirmButton = true;

    if (options.showConfirmButton !== undefined) {
        showConfirmButton = options.showConfirmButton;
    }

    Swal.fire({
        showConfirmButton: showConfirmButton,
        title: _options.title,
        showCancelButton: false,
        width: 600,
        heightAuto: true,
        padding: 30,
        //text: errorText || "Произошла ошибка",
        html: errorText || "Произошла ошибка",
        confirmButtonText: 'Закрыть',
        cancelButtonText: _options.cancelButtonText || 'Отменить',
        buttonsStyling: false,
        allowOutsideClick: false,
        customClass: {
            confirmButton: 'btn btn-danger btn-type-2',
            title: 'modal-title',
            cancelButton: 'btn btn-default btn-type-2',
            container: 'modal-content',
        },
        didOpen: () => {
            _options.didOpenCallback();
        },
        preConfirm: () => {
            return _options.preConfirmCallback();
        }
    }).then(function (result) {
        _options.thenCallback(result);
    });

}

export function errorSessionModal() {
    Swal.fire({
        showConfirmButton: true,
        title: 'Ошибка',
        showCancelButton: false,
        width: 600,
        heightAuto: true,
        padding: 30,
        text: "Сессия завершилась. Перезагрузите страницу.",
        confirmButtonText: 'Закрыть',
        cancelButtonText: 'Отменить',
        buttonsStyling: false,
        allowOutsideClick: false,
        customClass: {
            confirmButton: 'btn btn-danger btn-type-2',
            title: 'modal-title',
            cancelButton: 'btn btn-default btn-type-2',
            container: 'modal-content',
        },
        didOpen: () => {
        },
        preConfirm: () => {
            return window.location.reload();
        }
    }).then(function (result) {
    });

}

export function successModal(
    text,
    options
) {
    if (!options) {
        options = {};
    }

    const _options = {
        title: options.title || '',
        didOpenCallback: options.didOpenCallback || function () { },
        preConfirmCallback: options.preConfirmCallback || function () { },
        thenCallback: options.thenCallback || function () { },
    };
    Swal.fire({
        title: _options.title,
        showCancelButton: false,
        width: 600,
        heightAuto: true,
        padding: 30,
        //text: text,
        html: text,
        confirmButtonText: 'Закрыть',
        buttonsStyling: false,
        allowOutsideClick: false,
        customClass: {
            confirmButton: 'btn btn-danger btn-type-2',
            title: 'modal-title',
            cancelButton: 'btn btn-default btn-type-2',
            container: 'modal-content',
        },
        didOpen: () => {
            _options.didOpenCallback();
        },
        preConfirm: () => {
            return _options.preConfirmCallback();
        }
    }).then(function (result) {
        _options.thenCallback(result);
    });

}

export function questionModal(
    text,
    options
) {
    if (!options) {
        options = {};
    }

    const _options = {
        title: options.title || 'Подтверждение действия',
        didOpenCallback: options.didOpenCallback || function () { },
        preConfirmCallback: options.preConfirmCallback || function () { },
        thenCallback: options.thenCallback || function () { },
    };

    let showConfirmButton = true;

    if (options.showConfirmButton !== undefined) {
        showConfirmButton = options.showConfirmButton;
    }

    Swal.fire({
        title: _options.title,
        showCancelButton: true,
        showConfirmButton: showConfirmButton,
        width: 600,
        heightAuto: true,
        padding: 30,
        text: text,
        confirmButtonText: 'Да',
        cancelButtonText: 'Отменить',
        buttonsStyling: false,
        allowOutsideClick: false,
        customClass: {
            confirmButton: 'btn btn-danger btn-type-2',
            title: 'modal-title',
            cancelButton: 'btn btn-default btn-type-2',
            container: 'modal-content question-modal',
        },
        didOpen: () => {
            _options.didOpenCallback();
        },
        preConfirm: () => {
            return _options.preConfirmCallback();
        },
    }).then(function (result) {
        _options.thenCallback(result);
    });

}

export function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
}
